import { render, staticRenderFns } from "./InviteRewards.vue?vue&type=template&id=411b4058&scoped=true&"
import script from "./InviteRewards.vue?vue&type=script&lang=js&"
export * from "./InviteRewards.vue?vue&type=script&lang=js&"
import style0 from "styles/components/queryHeader.css?vue&type=style&index=0&id=411b4058&scoped=true&lang=css&"
import style1 from "styles/views/account/inviteRewards.css?vue&type=style&index=1&id=411b4058&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "411b4058",
  null
  
)

export default component.exports